import { ScoreDigitGroup } from "./ScoreDigitGroup";
import { ScoreBoardWrapper } from "./ScoreBoardWrapper";

export const ScoreBoard = (props) => {
  const {
    scoreReady,
    beats = 0,
    pointSections = {},
    coePack = {},
  } = props;
  const {
    assets = { total: 0 },
    waste = { total: 0 },
    una = { total: 0 },
  } = pointSections;
  const { totVal = 0, totalCoe = 0 } = coePack;

  return (
    <ScoreBoardWrapper>
      <div
        className="scoreback unselectable"
        style={{
          // width: 300,
          backgroundColor: "transparent",
          position: "relative",
          padding: 6,
          display: "flex",
        }}
      >
        <ScoreDigitGroup
          title="TOT.VAL"
          number={!scoreReady ? "COH" : totVal}
          digitCount={3}
        />
        <ScoreDigitGroup
          title="WASTE"
          number={!scoreReady ? "ADO" : waste.total}
          digitCount={3}
        />
        <ScoreDigitGroup title="BEATS" number={beats} digitCount={2} />
        <ScoreDigitGroup title="UNA" number={una.total} digitCount={3} />
        <ScoreDigitGroup title="COE" number={totalCoe} digitCount={3} />
      </div>
    </ScoreBoardWrapper>
  );
};
