export const buildAvgCoe = (rounds) => {
  try {
    const sum = rounds.reduce((sum, roundData) => {
      const { coePack = {}, coe = 0 } = roundData;
      const { totalCoe = coe } = coePack;
      return sum + totalCoe;
    }, 0);
    const avg = sum / rounds.length;

    return Math.round(avg);
  } catch (error) {
    console.log("🚀 ~ buildAvgCoe ~ error:", error);
    return 1;
  }
};
