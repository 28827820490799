import { useState, useRef, useMemo, useCallback } from "react";
import { ThreeWrap } from "./ThreeUtills/ThreeWrap";
import { CohadoScene } from "./CohadoScene";
import { useThreehadoStates } from "./useThreehadoStates";
import { useCloudGame } from "./firebase/useCloudGame";
import { useSearchParamGameId } from "./useSearchParamGameId";
import { buildDrawBones } from "./buildDrawBones";
import { getCurrentUser } from "./firebase";
import { sortBoneStates } from "./useThreehadoStates/sortBoneStates";
import { ActionBar } from "./ActionBar";
import { orbitalRef$ } from "./ThreeUtills/useOrbitalRef";
import { getLastCam, setLastCam } from "./lastCam";
import { PlatformControl } from "./PlatformControl";
import { BoardImgControl } from "./BoardImgControl";
import { ControlPalette } from "./ControlPalette";
import { CommunityFeedBackButtonContatiner } from "./ActionBar/CommunityFeedBackButtonContatiner";
import { getLocalFeature } from "../KeyMenu/localFeatureConfig";
import { BottomBar } from "./BottomBar";
import { ConfirmModal } from "./BoardImgControl/ConfirmModal";
import { useControlPalette } from "./ControlPalette/useControlPalette";
// import 'pickwait.css'

export const ThreeHado = () => {
  const [newGameMenu, setNewGameMenu] = useState(false);

  const {
    pointSections,
    onEnd,
    boneStates,
    validState,
    removeBone = () => {},
    shouldUpdateBoneStates = () => {},
  } = useThreehadoStates();

  const cohadoStates = useMemo(() => {
    return {
      boneStates,
    };
  }, [boneStates]);

  const [gameSnap, setGameSnap] = useState("/images/start.png");
  const [showScene, setShowScene] = useState(false);

  const [showLoader, setShowLoader] = useState(true);

  const [hidePlatforms, setHidePlatforms] = useState(false);

  const [showPlatformControl, setShowPlatformControl] = useState(false);

  const [showBoardImgControl, setShowBoardImgControl] = useState(false);

  const [showPalette, setShowPalette] = useState(false);

  const onClickGetSummaryCard = useCallback(() => {
    setShowBoardImgControl(true);
  }, [setShowBoardImgControl]);

  const togglePlatformControl = useCallback(() => {
    setShowPlatformControl(!showPlatformControl);
  }, [showPlatformControl]);

  const cutRefreshCanvas = useCallback(
    async (startImage) => {
      setShowLoader(true);
      try {
        const cam = getLastCam();
        cam.userData.controltarget = orbitalRef$.getValue().target;
        setLastCam(cam);
        setGameSnap("/images/start.png");
      } catch (error) {
        setShowLoader(false);
      }
    },
    [showLoader, setShowLoader, gameSnap, setGameSnap, showScene, setShowScene]
  );

  const {
    cloudGame,
    initCloudGame,
    isMyTurn,
    iAmhost,
    callNextTurn,
    setPlayerHand,
    setPlayerOrder,
    callPieceOutForPlayer,
    turnState,
    localHand,
    callEndGame,
    turn,
    reset,
    nextRound,
    onHandSort,
    onClickSaveSummaryImage,
  } = useCloudGame({
    setHidePlatforms,
    pointSections,
    cutRefreshCanvas,
    shouldUpdateBoneStates,
    cohadoStates,
  });

  const [selectedDrawWellBone, setSelectedDrawWellBone] = useState({});

  const drawWell = useMemo(() => {
    if (cloudGame) {
      const { well = [] } = cloudGame;
      return well; // well.sort(() => Math.random() - 0.5);
    }
    return [];
  }, [cloudGame]);

  const [unlockShowWell, setunlockShowWell] = useState(false);

  const showWell = useMemo(() => {
    if (cloudGame) {
      if (unlockShowWell) {
        return unlockShowWell;
      }

      const {
        well = [],
        cohadoStates = {},
        turn = {},
        playerHands = {},
      } = cloudGame;

      const { boneStates = {} } = cohadoStates;

      const turnHand = playerHands[turn.id] || [];

      const leftBones = turnHand.filter((bone) => !boneStates[bone.boneKey]);
      const liveBoneCount = Object.keys(boneStates).length;
      const turnBoneCount = turn.boneCount || 0;
      const satPlayState = liveBoneCount > turnBoneCount;

      return (
        isMyTurn &&
        turnState !== "pickBones" &&
        !satPlayState &&
        localHand.length > 0 &&
        leftBones.length === 0 &&
        well.length > 0
      );
    }
  }, [cloudGame, unlockShowWell, turnState, isMyTurn]);

  const selectDrawWellbone = useCallback(
    (selectedbone) => {
      const { boneKey } = selectedbone;

      const unDropedSelected = drawWell.filter(
        (bone) =>
          !boneStates[bone.boneKey] && bone.boneKey == selectedDrawWellBone
      );

      if (unDropedSelected.length === 0) {
        setSelectedDrawWellBone(boneKey);
      }
    },
    [
      drawWell,
      isMyTurn,
      boneStates,
      selectedDrawWellBone,
      setSelectedDrawWellBone,
    ]
  );
  // share pick
  // const drawBones = useMemo(() => {
  //   if (cloudGame && turn) {
  //     const { well = [], playerHands = {} } = cloudGame;
  //     const { state } = turn;

  //     const shareStartDraw = getLocalFeature('shareStartDraw')

  //     if (state == "pickBones" && !isMyTurn && shareStartDraw) {
  //       // const draws = buildDrawBones({
  //       //   well,
  //       // });
  //       return [...well.map((bone) => ({ ...bone, active: false })),];
  //     }

  //     if (state == "pickBones" && isMyTurn) {

  //       const user = getCurrentUser();
  //       const playerHand = playerHands[user.id] || [];

  //       return [...well.map((bone) => ({ ...bone, active: false })), ...playerHand.map((bone) => ({ ...bone, active: true })),];
  //     }

  //   }
  //   return [];
  // }, [cloudGame, turn]);

  const drawBones = useMemo(() => {
    if (cloudGame && turn) {
      const { well = [] } = cloudGame;
      const { state } = turn;
      if (state == "pickBones") {
        // const draws = buildDrawBones({
        //   well,
        // });
        return [...well];
      }
    }
    return [];
  }, [cloudGame, turn]);

  // const canPick = useMemo(() => showWell && isMyTurn, [isMyTurn, showWell])

  const bones = useMemo(() => {
    const sortedStateDrops = sortBoneStates(boneStates).map((bone) => {
      return {
        ...bone,
        parentId: "board",
      };
    });

    if (localHand && boneStates && drawWell) {
      const liveHand = localHand.filter((bone) => {
        return !boneStates[bone.boneKey];
      });

      const localHandBones = liveHand.map((bone, i) => {
        return {
          ...bone,
          parentId: "platform",
          initPosition: {
            rotation: getLocalFeature("boneSort")
              ? bone.initPosition.rotation
              : 0,
            xGridPoint: i * 0.22 - (1.45 - 0.11 * (14 - liveHand.length)),
            zGridPoint: 0,
            yFloat: 0,
          },
        };
      });

      const unusedWellbones = drawWell
        .filter((bone) => {
          return !boneStates[bone.boneKey];
        })
        .map((bone) => {
          return {
            ...bone,
            parentId: "wellPlatform",
            // mode: canPick ? 'canPick' : '',
            active: selectedDrawWellBone === bone.boneKey,
          };
        })
        .map((bone, i) => {
          return {
            ...bone,
          };
        });

      return [...sortedStateDrops, ...localHandBones, ...unusedWellbones];
    }
    return [];
  }, [
    localHand,
    boneStates,
    drawWell,
    selectedDrawWellBone,
    //  canPick
  ]);

  useSearchParamGameId(initCloudGame, cloudGame);

  const [videoChatOpen, setVideoChatOpen] = useState(false);

  // const { streamers, turnOnCam } = useStreamers({ cloudGame });

  const [selectedDrawbones, setselectedDrawbones] = useState({});

  const drawBoneCompleteLock = useRef(false);

  const onDrawBonesComplete = useCallback(
    async (selectedHand) => {
      if (drawBoneCompleteLock.current === false) {
        drawBoneCompleteLock.current = true;
        const user = getCurrentUser();
        const selectedHandBoneKey = selectedHand.map((item) => item.boneKey);
        const well = [
          ...drawBones.filter(
            (bone) => !selectedHandBoneKey.includes(bone.boneKey)
          ),
        ];

        const handReset = selectedHand.map((bone) => {
          const boneReset = {
            ...bone,
            initPosition: {
              ...bone.initPosition,
              rotation: 0,
            },
          };

          return {
            ...boneReset,
          };
        });

        await setPlayerHand({
          initialHand: handReset,
          selectedHand: handReset,
          well,
        });

        const { playerOrderList = [] } = cloudGame;

        const last = playerOrderList[playerOrderList.length - 1];

        if (playerOrderList.length == 1 || (last && last.id === user.id)) {
          await callNextTurn();
        } else {
          await callNextTurn({ state: "pickBones" });
        }
      }
    },
    [callNextTurn, setPlayerHand, cloudGame, drawBones, drawBoneCompleteLock]
  );

  const onDrawBoneSelect = useCallback(
    (bone) => {
      const { boneKey } = bone;
      const { bonesPerPlayer } = cloudGame;
      const selectionUpdate = {
        ...selectedDrawbones,
        [boneKey]: {
          ...bone,
        },
      };
      const drawCount = Object.keys(selectionUpdate).length;
      const selectedHand = [
        ...Object.keys(selectionUpdate).map((boneKey) => ({
          ...selectionUpdate[boneKey],
        })),
      ];

      if (drawCount < bonesPerPlayer) {
        setselectedDrawbones(selectionUpdate);
      } else if (drawCount === bonesPerPlayer) {
        onDrawBonesComplete(selectedHand);
      }
    },
    [selectedDrawbones, cloudGame, onDrawBonesComplete]
  );

  // share
  // const onDrawBoneSelect = useCallback(
  //   (bone) => {
  //     const { boneKey } = bone;
  //     const { bonesPerPlayer, playerHands = {} } = cloudGame;
  //     const user = getCurrentUser();
  //     const selectedHand = playerHands[user.id] || [];

  //     // const selectionUpdate = {
  //     //   ...selectedDrawbones,
  //     //   [boneKey]: {
  //     //     ...bone,
  //     //   },
  //     // };

  //     selectedHand.push(bone)
  //     const drawCount = selectedHand.length  //Object.keys(selectionUpdate).length;
  //     // const selectedHand = [
  //     //   ...Object.keys(selectionUpdate).map((boneKey) => ({
  //     //     ...selectionUpdate[boneKey],
  //     //   })),
  //     // ];

  //     if (drawCount < bonesPerPlayer) {
  //       // setselectedDrawbones(selectionUpdate);

  //       const selectedHandBoneKey = selectedHand.map((item) => item.boneKey);

  //       const well = [
  //         ...drawBones.filter(
  //           (bone) => !selectedHandBoneKey.includes(bone.boneKey)
  //         ),
  //       ];
  //       setPlayerHand({
  //         endWellPick: true,
  //         initialHand: selectedHand,
  //         selectedHand,
  //         well,
  //       });

  //     } else if (drawCount === bonesPerPlayer) {
  //       onDrawBonesComplete(selectedHand);
  //     }
  //   },
  //   [selectedDrawbones, cloudGame, onDrawBonesComplete]
  // );

  const soladoMode = useMemo(() => {
    const { playerOrderList = [] } = cloudGame || {};

    return playerOrderList.length === 1;
  }, [cloudGame]);

  const [boardRotation, setBoardRotation] = useState(0);

  const rotate = useCallback(() => {
    const full = -360 * (Math.PI / 180);

    return setBoardRotation((rotation) => {
      const next = rotation - 60 * (Math.PI / 180);

      if (next === full) {
        return 0;
      }

      if (next < full) {
        return next - full;
      }

      if (next > 0) {
        return next + full;
      }

      return next;
    });
  }, [setBoardRotation]);

  const turnCount = useMemo(() => {
    if (cloudGame && cloudGame.turn) {
      const { playerOrderList = [], turn = {} } = cloudGame;

      const turnCount = playerOrderList
        .map((player) => player.id)
        .indexOf(turn.id);
      return turnCount + 1;
    }
  }, [cloudGame]);

  return (
    <>
      <div
        // className={`pickWait position-absolute h-100 w-100  ${!isMyTurn && drawBones.length > 0 ?'':'off'}`}
        className="pickWait position-absolute h-100 w-100 "
        style={{
          top: 0,
          left: 0,
          opacity: 0.7,
          backgroundColor: "#2c2a2a",
          zIndex: 40,
          display: !isMyTurn && drawBones.length > 0 ? "block" : "none",
        }}
      >
        <video
          style={{
            position: "absolute",
            top: 0,
            backgroundColor: "transparent",
            left: 0,
            height: "100%",
            width: "100%",
            objectFit: "fill",
          }}
          autoPlay
          muted
          loop
          src="https://firebasestorage.googleapis.com/v0/b/big-hado.appspot.com/o/cohado-public%2Fvideos%2Fpickwaitwater.mp4?alt=media&token=c1041099-e3d4-4462-ab1d-339ba72961c0"
        ></video>
        <img
          style={{
            position: "relative",
            top: "15%",
            margin: "auto",
          }}
          src={`./images/${turnCount}glow.png`}
          alt=""
        />
      </div>

      <div className="h-100 game-container">
        <ActionBar
          onClickGetSummaryCard={onClickGetSummaryCard}
          showLoader={showLoader}
          callPieceOutForPlayer={callPieceOutForPlayer}
          callEndGame={callEndGame}
          nextRound={nextRound}
          pointSections={pointSections}
          setunlockShowWell={setunlockShowWell}
          reset={reset}
          showPlatformControl={showPlatformControl}
          rotate={rotate}
          cutRefreshCanvas={cutRefreshCanvas}
          setShowScene={setShowScene}
          soladoMode={soladoMode}
          videoChatOpen={videoChatOpen}
          setVideoChatOpen={setVideoChatOpen}
          cloudGame={cloudGame}
          iAmhost={iAmhost}
          turnState={turnState}
          setNewGameMenu={setNewGameMenu}
          validState={validState}
          callNextTurn={callNextTurn}
          isMyTurn={isMyTurn}
          setPlayerOrder={setPlayerOrder}
          newGameMenu={newGameMenu}
        />
        {showLoader && (
          <div
            className="h-100"
            style={{
              zIndex: 10,
              position: "absolute",
              width: "100%",
            }}
          >
            <div
              className="h-100"
              style={{
                zIndex: 5,
                width: "100%",
                position: "absolute",
                backgroundColor: "#2c2a2a",
                opacity: 0.4,
              }}
            />
            <div
              className="h-100"
              style={{
                zIndex: 10,
                width: "100%",
                position: "absolute",
                backgroundColor: "transparent",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img className="w-25" src="/images/zerosumbol3.gif" alt="" />
            </div>
            <img
              id="theimage"
              onLoad={(event) => {
                event.target.style.opacity = 1;
                setShowScene(false);
                setTimeout(() => {
                  setShowScene(true);
                  setTimeout(() => {
                    setShowLoader(false);
                    setGameSnap("");
                  }, 2000);
                }, 1000);
              }}
              className="h-100 w-100"
              style={{
                top: 0,
                left: 0,
                zIndex: 1,
                position: "absolute",
                backgroundColor: "#2c2a2a",
                opacity: 0,
              }}
              src={gameSnap}
              alt=""
            />
          </div>
        )}
        <PlatformControl
          togglePlatformControl={togglePlatformControl}
          showPlatformControl={showPlatformControl && !showLoader && showScene}
        />
        <BoardImgControl
          onClickSaveSummaryImage={onClickSaveSummaryImage}
          setShowBoardImgControl={setShowBoardImgControl}
          setHidePlatforms={setHidePlatforms}
          showBoardImgControl={showBoardImgControl && !showLoader && showScene}
        />
        <ConfirmModal />
        <ControlPalette
          showPalette={showPalette}
          setShowPalette={setShowPalette}
        />
        {showScene && (
          <ThreeWrap backgroundColor="#2c2a2a">
            <CohadoScene
              onHandSort={onHandSort}
              togglePlatformControl={togglePlatformControl}
              setShowPalette={setShowPalette}
              setunlockShowWell={setunlockShowWell}
              unlockShowWell={unlockShowWell}
              hidePlatforms={hidePlatforms}
              showWell={showWell}
              boardRotation={boardRotation}
              bones={bones}
              setPlayerHand={setPlayerHand}
              localHand={localHand}
              drawWell={drawWell}
              selectDrawWellbone={selectDrawWellbone}
              boneStates={boneStates}
              turn={turn}
              isMyTurn={isMyTurn}
              removeBone={removeBone}
              onEnd={onEnd}
              drawBones={drawBones}
              selectedDrawbones={selectedDrawbones}
              onDrawBoneSelect={onDrawBoneSelect}
            />
          </ThreeWrap>
        )}
      </div>
      <CommunityFeedBackButtonContatiner />
      <BottomBar cloudGame={cloudGame} />
    </>
  );
};
