const baseNumber = 224;

export const calculateCoe = ({ pointSections = {}, beats =0 }, coPack) => {
  const {
    assets = { total: 0 },
    waste = { total: 0 },
    una = { total: 0 },
  } = pointSections;

  const totVal = assets.total + waste.total + una.total;

  const beatsWastesValue = beats ? beats * waste.total : waste.total;

  const negs = beatsWastesValue + una.total;
  
  const aggCoe = assets.total - negs;

  const totalCoe = totVal - negs;

  if (coPack) {
    return {
      negs,
      totVal,
      aggCoe,
      totalCoe,
      beatsWastesValue,
    };
  }

  return aggCoe;
};
