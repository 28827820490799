// import { VideoChat } from "./VideoChat";
import { VideoChatAgora } from "./VideoChatAgora";
import { KeyMenu } from "../../KeyMenu";
import { PlayerOrder } from "./PlayerOrder";
import { useEffect, useMemo, useState } from "react";
import { ScoreBoard } from "./ScoreBoard";
import { initActionBarVertSlide } from "./initActionBarVertSlide";
import { ScoreSummaryModal } from "./ScoreSummaryModal";
import { calculateCoe } from "../useThreehadoStates/calculateCoe";
import { CohadoAlert } from "./CohadoAlert";
import { BeatModal } from "./BeatModal";
import { CommunityFeedBackPopUp } from "./CommunityFeedBackPopUp";
import { BeatMsg } from "./BeatMsg";
import { PhaseImg } from "./PhaseImg";
// import {CommunityFeedBackButtonContatiner} from './CommunityFeedBackButtonContatiner'

export const nextFlash = { fire: () => {} };

export const ActionBar = (props) => {
  const {
    nextRound,
    soladoMode,
    showLoader,
    setVideoChatOpen,
    setunlockShowWell = () => {},
    iAmhost,
    turnState,
    pointSections,
    cutRefreshCanvas,
    validState,
    callNextTurn,
    isMyTurn,
    setPlayerOrder,
    newGameMenu,
    setNewGameMenu,
    videoChatOpen,
    cloudGame,
    onClickGetSummaryCard,
    callEndGame = () => {},
    rotate = () => {},
    reset = () => {},
    callPieceOutForPlayer = () => {},
  } = props;

  const beats = cloudGame && cloudGame.beats ? cloudGame.beats : 0;

  const coePack = calculateCoe({ pointSections, beats },true);
  const [videoDeviceList, setVideoDeviceList] = useState([]);
  const scoreReady = turnState && turnState !== "pickBones";

  const nextActive =
    scoreReady &&
    isMyTurn &&
    validState &&
    turnState &&
    turnState !== "pickBones";

  const liveBoneCount = useMemo(() => {
    const { cohadoStates = {} } = cloudGame || {};
    const { boneStates = {} } = cohadoStates;
    const liveBoneCount = Object.keys(boneStates).length;
    return liveBoneCount;
  }, [cloudGame]);

  const turn = useMemo(() => {
    const { turn = {} } = cloudGame || {};
    return turn;
  }, [cloudGame]);

  const canShowPickOnBeatAlert = useMemo(() => {
    const { turns = [], playerOrderList = [] } = cloudGame || {};

    const pastRoundTurns = [...turns]
      .reverse()
      .slice(0, playerOrderList.length);
    console.log(
      "🚀 ~ file: index.js:73 ~ can ~ pastRoundTurns",
      pastRoundTurns
    );

    const pastRoundTurnsTookBeat = pastRoundTurns
      .map((oldTurn) => oldTurn.tookBeat)
      .filter((tookBeat) => tookBeat);
    console.log(
      "🚀 ~ file: index.js:76 ~ can ~ pastRoundTurnsTookBeat",
      pastRoundTurnsTookBeat
    );

    return pastRoundTurns.length == pastRoundTurnsTookBeat.length;
  }, [turn.stamp]);

  const roundIndex = useMemo(() => {
    const { roundData = {} } = cloudGame || {};
    const { roundIndex = 0 } = roundData;
    return roundIndex;
  }, [cloudGame]);

  const allBonesOnBoard = useMemo(() => {
    return liveBoneCount == 28;
  }, [liveBoneCount]);

  const satPlayState = useMemo(() => {
    const { turn = {} } = cloudGame || {};

    const turnBoneCount = turn.boneCount || 0;

    return liveBoneCount > turnBoneCount;
  }, [cloudGame]);

  useEffect(() => {
    initActionBarVertSlide();
  }, []);

  const [flashNextButton, setFlashNextButton] = useState(false);

  const nextButtonText = useMemo(() => {
    if (allBonesOnBoard) {
      return "End";
    }

    return "Next";
  }, [cloudGame]);

  const nextButtonClass = useMemo(() => {
    if (allBonesOnBoard) {
      return "menubutton-on";
    }

    if (flashNextButton && isMyTurn) {
      return "next-turn-flash";
    }

    if (!isMyTurn || !scoreReady) {
      return "menubutton-grey";
    }

    const mainNextClass =
      nextActive && satPlayState ? "menubutton-green-flash" : "menubutton-green";

    return `${mainNextClass}`;
  }, [nextActive, satPlayState, flashNextButton,isMyTurn]);

  useEffect(() => {
    if (isMyTurn) {
      setFlashNextButton(!satPlayState);
    }
  }, [turn.boneCount, isMyTurn, satPlayState]);

  useEffect(() => {
    const cancelFlash = () => {
      if (flashNextButton) {
        setFlashNextButton(false);
      }
    };

    nextFlash.fire = () => {
      cancelFlash();
    };

    return () => {};
  }, [setFlashNextButton, flashNextButton]); //

  const onNextButtonClick = () => {
    if (liveBoneCount > 0) {
      if (allBonesOnBoard) {
        const { turn = {} } = cloudGame || {};

        const turnBoneCount = turn.boneCount || 0;

        if (turnBoneCount === 28) {
          const myModal = new window.bootstrap.Modal(
            document.getElementById("scoreSummary"),
            {
              keyboard: false,
            }
          );
          myModal.show();
        } else {
          callEndGame();
        }
      } else {
        if (nextActive) {
          if (satPlayState) {
            callNextTurn();
          } else {
            const myModal = new window.bootstrap.Modal(
              document.getElementById("beatMoveModal"),
              {
                keyboard: false,
              }
            );
            myModal.show();
          }
        }
      }
    }
  };

  const showStartScreen = useMemo(
    () => cloudGame && !turnState && !showLoader,
    [cloudGame, iAmhost, turnState, showLoader]
  );

  return (
    <>
      {/* 
      <div 
        style={{
          display: 'flex'
        }}
        className="hubbar-scroll actionslide">
        <div className="nodiv" />
      </div>
       */}

      <div className="hubBar barboneback actionslide">
        {/* <span className="placeLogo" >Cohado</span> */}
        <img
          style={{ height: 100, marginRight: 36 }}
          src="/images/topbarlogo2.png"
          alt=""
        />
        <PhaseImg
          onClick={() => {
            const myModal = new window.bootstrap.Modal(
              document.getElementById("keyMenu"),
              {
                keyboard: false,
              }
            );
            myModal.show();
          }}
          time={10000}
          style={{ height: 100, marginRight: 36 }}
          phaseSrcs={["/images/KEYA.gif", "/images/KEYAstop.gif"]}
        />

        {
          <div
            onClick={() => {
              onNextButtonClick();
            }}
            className={nextButtonClass}
          >
            {nextButtonText}
          </div>
        }

        {/* {cloudGame && iAmhost && turnState && (
          <div
            onClick={() => {
              reset();
            }}
            className="menubutton"
          >
            <span>
              reset game
            </span>
          </div>
        )} */}

        {!soladoMode && (
          <div
            onClick={() => {
              setVideoChatOpen((chatOpen) => !chatOpen);
            }}
            className="menubutton"
          >
            <span>Players</span>
          </div>
        )}
        {/* <div
          onClick={() => {
            cutRefreshCanvas();
          }}
          className="menubutton"
        >
          cutRefreshCanvas
        </div>  */}
        {/* {cloudGame && !soladoMode && (
          <div
            onClick={async () => {
              if (
                "mediaDevices" in navigator &&
                "getUserMedia" in navigator.mediaDevices
              ) {
                console.log("Let's get this party started");
              }
              // const devices = await AgoraRTC.getCameras()
              const devices = await navigator.mediaDevices.enumerateDevices();
              console.log(
                "🚀 ~ file: index.js ~ line 130 ~ onClick={ ~ devices",
                devices
              );

              const videoDevices = devices.filter(
                (device) => device.kind === "videoinput"
              );
              setVideoDeviceList([...videoDevices]);
              const myModal = new window.bootstrap.Modal(
                document.getElementById("shareOptionsModal"),
                {
                  keyboard: false,
                }
              );

              myModal.show();
            }}
            className="menubutton"
          >
            Stream
          </div>
        )} */}

        <div
          className="flex-1"
          style={{
            minWidth: 100,
          }}
        />

        <ScoreBoard
          pointSections={pointSections}
          scoreReady={scoreReady}
          beats={beats}
          coePack={coePack}
        />

        {/* <div

          style={{
            width: 300
          }}
        ></div> */}
      </div>

      {cloudGame && (
        <PlayerOrder
          iAmhost={iAmhost}
          showStartScreen={showStartScreen}
          setPlayerOrder={setPlayerOrder}
          cloudGame={cloudGame}
          show={newGameMenu}
          setShow={setNewGameMenu}
        />
      )}

      <VideoChatAgora
        cloudGame={cloudGame}
        onClose={() => {
          setVideoChatOpen(false);
        }}
        open={videoChatOpen}
      />

      <ScoreSummaryModal
        onClickGetSummaryCard={onClickGetSummaryCard}
        iAmhost={iAmhost}
        roundIndex={roundIndex}
        isMyTurn={isMyTurn}
        nextRound={nextRound}
        liveBoneCount={liveBoneCount}
        allBonesOnBoard={allBonesOnBoard}
        coePack={coePack}
        beats={beats}
        pointSections={pointSections}
      />

      <CohadoAlert />

      <BeatMsg />
      <KeyMenu
        inGame
        soladoMode={soladoMode}
        rotate={rotate}
        callPieceOutForPlayer={callPieceOutForPlayer}
      />

      <BeatModal
        setunlockShowWell={setunlockShowWell}
        callNextTurn={callNextTurn}
        canShowPickOnBeatAlert={canShowPickOnBeatAlert}
        nextActive={nextActive}
      />
      <CommunityFeedBackPopUp />
    </>
  );
};
